import React from 'react';
import Style from './Home.module.scss';
import me from '../../img/peter.jpg';
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import { useMediaQuery } from '@mui/material';
import {Box} from "@mui/material";
import {info} from "../../info/Info";

export default function Home({innerRef}) {

   const isMobile = useMediaQuery('(max-width:640px)');

   return (
      
      <Box ref={innerRef} component={'main'} display={'flex'} flexDirection={{xs: 'column', md: 'row'}} alignItems={'center'}
           justifyContent={'center'} minHeight={'calc(100vh - 175px)'} id={'home'}>
         <Box className={classNames(Style.avatar, Style.shadowed)} alt={'image of developer'} style={{background: info.gradient}} component={'img'} src={me} width={{xs: '35vh', md: '40vh'}}
              height={{xs: '35vh', md: '40vh'}}
                mb={{xs: '1rem', sm: 0}} mr={{xs: 0, md: '2rem'}}/>
         <Box>
            <h1>Peter Sidlauskas <span style={{background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}></span><span className={Style.hand}></span>
            </h1>
            <h3>Front End Developer ﹡ Shopify Developer ﹡ Web Designer ﹡ Video Editor</h3><br></br>
            <p><a href="mailto:peter@bronze56k.com">peter@bronze56k.com</a></p>
           
            <Box component={'ul'} p={'0.4rem'}>
              
            </Box>
            <Box display={'flex'} gap={'0.2rem'} justifyContent={isMobile ? 'center' : 'left'} marginBottom={'20px'} fontSize={{xs: '2rem', md: '2.5rem'}}>
               {info.socials.map((social, index) => (
                  <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
               ))}
            </Box>
            <h2>Selected Work</h2><br></br>
            <p><a href="https://bronze56k.com" target="_blank">Bronze56k</a> ﹡ <a href="https://awakenyclothing.com/" target="_blank">Awake NY</a> ﹡ <a href="https://hardbodyskateboards.com" target="_blank">Hardbody</a> ﹡ <a href="https://pangeajeans.com/" target="_blank">Pangea Jeans</a> ﹡ <a href="https://alltimers.com" target="_blank">Alltimers</a></p>
            <p><a href="https://redbullcultureclashnyc.myshopify.com/" target="_blank">Red Bull</a> ﹡ <a href="https://sexhippies.com" target="_blank">SexHippies</a> ﹡ <a href="https://superpollo.nyc" target="_blank">Super Pollo</a> ﹡ <a href="https://billymcfeely.com" target="_blank">Billy McFeely</a> ﹡ <a href="https://jpins.com" target="_blank">Petschauer Inc.</a></p>
            
         </Box>
         
      </Box>
   )
}